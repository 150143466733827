import React from "react";

//React Router DOM importation
import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "../auth/protected-route/ProtectedRoutes";


// context importation
import { UserProvider } from "../context/UserProvider.jsx";
import { LangProvider } from "../context/LangProvider.jsx";


//Views Importation
import { LoginView } from "../auth/views/LoginView";
import { CreateCustomerComp, CustomerDetailsComp } from "../components/customer-components";
import { ServiceCallsDetails, NewCall } from "../components/serviceComponents";
import { CustomersView } from "../views/customers/CustomersView";
import { NewEmployee } from "../views/employees/NewEmployee";
// import { EmployeesView } from "../views/employees/EmployeesView";
import { EquipmentView } from "../views/equipment/EquipmentView";
import { HomeView } from "../views/home/HomeView";
import { HumanReView } from "../views/human-resources/HumanReView";
import { PageNotFound } from "../views/page-not-fund/PageNotFound";
import { ReportsView } from "../views/reports/ReportsView";
import { ServiceView } from "../views/service/ServiceView";
import { LogisticView } from "../views/logistic/LogisticView";
import { SolPrecioView } from "../views/sol-precio-view/SolPrecioView";
import { PartView } from "../views/part-view/PartView";
import { OrderDetailView } from "../views/order-detail/OrderDetailView";
import { SolRepuestoDetailView } from "../views/sol-repuesto-detail/SolRepuestoDetailView";
import { OrderTracker } from "../views/order-tracker/OrderTracker";
import { DataSupplierView } from "../views/data-suppliers/DataSupplierView";
import { ShipToLogisticView } from "../views/ship-to-logistic-view/ShipToLogisticView";
import { ModifyEmployee } from "../views/employees/ModifyEmployee";
import { SeguimientoLogistica } from "../views/seguimiento-logistica/SeguimientoLogistica";
import { LogisticReportsView } from "../views/logistic-reports-view/LogisticReportsView";
import { LogisticProjectionOrdersView } from "../views/logistic-reports-view/LogisticProjectionOrdersView";
import { VacacionesPermisosDetail } from "../views/vacaciones-permisos/VacacionesPermisosDetail";
import { SolicitudesVacaPerm } from "../views/vacaciones-permisos/SolicitudesVacaPerm";
import { AdministrationPending } from "../views/administration/AdministrationPending";
import { PropuestasPostVenta } from "../views/post-sales/PropuestasPostVenta";
import { PropuestaDetail } from "../views/post-sales/PropuestaDetail";
import { WareHouseViewPrincipal } from "../views/warehouse/WareHouseViewPrincipal";
import { QuotationViewPrincipal } from "../views/post-sales/QuotationViewPrincipal";
import { QuotationDetail } from "../views/post-sales/QuotationDetail";
import { CommercialPurchaseOrder } from "../views/commercial-view/CommercialPurchaseOrder";
import { CommePurchaseOrderDetail } from "../views/commercial-view/CommePurchaseOrderDetail";
import { CommercialReportsView } from "../views/commercial-reports/CommercialReportsView";
import { EquipmentDetailView } from "../views/equipment/EquipmentDetailView";
import { OrdTransitWaitingDispatch } from "../views/warehouse/OrdTransitWaitingDispatch";
import { TechnicalApprovalView } from "../views/warehouse/TechnicalApprovalsView";
import { TechnicalApprovalsDetail } from "../views/warehouse/TechnicalApprovalsDetailView";
import { SchedulingServiceView } from "../views/service/SchedulingServiceView";
import { ProjectsView } from "../views/commercial-view/ProjectsView";
import { ProjectsDetailView } from "../views/commercial-view/ProjectsDetailView";
import { FMIView } from "../views/service/FMIView";
import { FMIDetail } from "../views/service/FMIDetail";
import { NotaEntregaDetailView } from "../views/warehouse/NotaEntregaDetailView";
import { CommercialRequestOrdersView } from "../views/commercial-view/CommercialRequestOrdersView";
import { ToolsView } from "../views/warehouse/ToolsView";
import { ServiceReport } from "../components/serviceComponents/ServiceReport";
import { RequestPartsView } from "../views/service/RequestPartsView";
import { AssetRequestConsumablesView } from "../views/service/AssetRequestConsumablesView";
import { ServiceReportsDetailView } from "../views/service/ServiceReportsDetailView";
import { ToolsDetailView } from "../views/warehouse/ToolsDetailView";
import { EquipmentModifyView } from "../views/equipment/EquipmentModifyView";
import { HistoryExpensesView } from "../views/administration/HistoryExpensesView";
import { CustomerModifyView } from "../views/customers/CustomerModifyView";
import { DivisionDataView } from "../views/data/DivisionDataView";
import { CiudadDataView } from "../views/data/CiudadDataView";
import { LineaDataView } from "../views/data/LineaDataView";
import { ModalidadDataView } from "../views/data/ModalidadDataView";
import { TipEquipDataView } from "../views/data/TipEquipDataView";
import { ModeloDataView } from "../views/data/ModeloDataView";
import { TargetDataView } from "../views/data/TargetDataView";
import { ZonaDataView } from "../views/data/ZonaDataView";
import { RegionIslandDataView } from "../views/data/RegionIslandDataView";
import { ServiceReportsPrincipal } from "../views/service-reports-view/ServiceReportsPrincipal";
import { AdministrativeApprovals } from "../views/warehouse/AdministrativeApprovals";
import { AproSoporteDetail } from "../views/warehouse/AproSoporteDetail";
import { AdministrativeApprovalsDetail } from "../views/warehouse/AdministrativeApprovalsDetail";
import { LlegadaPartesDetail } from "../views/warehouse/LlegadaPartesDetail";
import { ShipToLogisticViewDetail } from "../views/ship-to-logistic-view/ShipToLogisticViewDetail";
import { NotaRetiroDetail } from "../views/warehouse/NotaRetiroDetail";
import { PendingCommercialView } from "../views/commercial-view/PendingCommercialView";
import { CreateOrderCommercial } from "../views/commercial-view/CreateOrderCommercial";
import { OrderDetailCommercialView } from "../views/commercial-view/OrderDetailCommercialView";
import { NotaEntregaDetailPrint } from "../views/warehouse/NotaEntregaDetailPrint";
import { ConfirRetPartesDetail } from "../views/warehouse/ConfirRetPartesDetail";
import { AnticipoPrincipal } from "../views/travel-expenses/AnticipoPrincipal";
import { MyTasksPending } from "../views/my-tasks/MyTasksPending";
import { AnticipoDetailView } from "../views/travel-expenses/AnticipoDetailView";
import { AnticipoDetalleDetail } from "../views/travel-expenses/AnticipoDetalleDetail";
import { CreateAnticipo } from "../views/travel-expenses/CreateAnticipo";
import { CreateDetalleAnticipo } from "../views/travel-expenses/CreateDetalleAnticipo";
import { AprobacionPrincipal } from "../views/travel-expenses/AprobacionPrincipal";
import { AprobacionDetail } from "../views/travel-expenses/AprobacionDetail";
import { AprobacionDetalleDetail } from "../views/travel-expenses/AprobacionDetalleDetail";
import { DevolucionView } from "../views/service/DevolucionView";
import { RelacionGastoPrincipal } from "../views/travel-expenses/RelacionGastoPrincipal";
import { RelacionGastoDetail } from "../views/travel-expenses/RelacionGastoDetail";
import { RelacionGastoDetailDetalle } from "../views/travel-expenses/RelacionGastoDetailDetalle";
import { RelacionGastoNueva } from "../views/travel-expenses/RelacionGastoNueva";
import { CreateDetalleRelacion } from "../views/travel-expenses/CreateDetalleRelacion";
import { OrderDetailViewRead } from "../views/order-detail/OrderDetailViewRead";
import { LogisticPiezasParaExportView } from "../views/logistic-reports-view/LogisticPiezasParaExportView";
import { ToolsHistory } from "../views/warehouse/ToolsHistory";
import { CommercialBackLogDetail } from "../views/commercial-view/CommercialBackLogDetail";
import { ServiceEquipComponents } from "../views/service/ServiceEquipComponents";
import { CreateEquipment } from "../views/equipment/CreateEquipment";
import { PartDevolucion } from "../views/service/PartDevolucion";
import { AuthorizationsView } from "../views/administration/AuthorizationsView";
import { CreateSolConsumoView } from "../views/administration/CreateSolConsumoView";
import { SolConsumoDetailView } from "../views/administration/SolConsumoDetailView";
import { SolConsumpViewPrin } from "../views/administration/SolConsumpViewPrin";
import { ReportsConsumRequest } from "../views/administration/ReportsConsumRequest";
import { CreateBackLogView } from "../views/commercial-view/CreateBackLogView";
import { BackLogDetail } from "../views/commercial-view/BackLogDetail";
import { ApplicationListView } from "../views/commercial-view/ApplicationListView";
import { CommercialProfile } from "../components/my-task-pending-comp/CommercialProfile";
import { LlamadasAbiertas } from "../views/commercial-reports/LlamadasAbiertas";
import { ListPagosViat } from "../views/administration/ListPagosViat";
import { ServiceProfile } from "../components/my-task-pending-comp/ServiceProfile";
import { CreateSupplierView } from "../views/data-suppliers/CreateSupplierView";
import { AssetRequestDetail } from "../views/service/AssetRequestDetail";
import { AproHerraDetail } from "../views/service/AproHerraDetail";
import { AssetRequestHerraDetail } from "../views/service/AssetRequestHerraDetail";
import { SalidaHerramientaView } from "../views/warehouse/SalidaHerramientaView";
import { PendingsRH } from "../views/human-resources/PendingsRH";
import { SitePlanningProfile } from "../components/my-task-pending-comp/SitePlanningProfile";
import { ReservationsView } from "../views/reservations/ReservationsView";
import { OperationProfile } from "../components/my-task-pending-comp/OperationProfile";

export const AppRouter = () => {

  return (
    <>
      <UserProvider>
        <LangProvider>
          <Routes>
            <Route path="login" element={<LoginView />} />
            <Route path="/" element={<Navigate to="login" />} />
            <Route path="*" element={<PageNotFound />} />

            {/* ----> Protected Routes <---- */}
            <Route element={<ProtectedRoutes />}>
              <Route path="home" element={<HomeView />} />

              {/* ----> CUSTOMERS ROUTES <---- */}
              <Route path="customers" element={<CustomersView />} />
              <Route path="customers/customer-detail/:id" element={<CustomerDetailsComp />} />
              <Route path="customers/customer-modify/:codCliente" element={<CustomerModifyView />} />
              <Route path="customers/new-customer" element={<CreateCustomerComp />} />

              {/* ----> EQUIPMENTS ROUTES <---- */}
              <Route path="equipments" element={<EquipmentView />} />
              <Route path="equipments/create-equipment/:codCliente" element={<CreateEquipment />} />
              <Route path="equipments/equipment-detail/:codEquipo" element={<EquipmentDetailView />} />
              <Route path="equipments/equipment-modify/:codEquipo" element={<EquipmentModifyView />} />

              {/* ----> SERVICE ROUTES <---- */}
              <Route path="service" element={<ServiceView grupo={1} />} />
              <Route path="service/service-call/:id" element={<ServiceCallsDetails grupo={1} />} />
              <Route path="service/service-report/:idCall" element={<ServiceReport grupo={1} />} />
              <Route path="service/service-report-detail/:codRepServ" element={<ServiceReportsDetailView grupo={1} />} />
              <Route path="service/request-part/:idCall" element={<RequestPartsView />} />
              <Route path="service/asset-request/:idCall" element={<AssetRequestConsumablesView />} />
              <Route path="service/asset-request-detail/:reqId" element={<AssetRequestDetail />} />
              <Route path="service/asset-approval-detail/:reqId" element={<AproHerraDetail />} />
              <Route path="service/asset-request-detail/:reqId/:reqDet" element={<AssetRequestHerraDetail />} />
              <Route path="new-call" element={<NewCall grupo={1} />} />
              <Route path="service/scheduling" element={<SchedulingServiceView />} />
              <Route path="service/fmi" element={<FMIView />} />
              <Route path="service/fmi/fmi-detail/:codFMI" element={<FMIDetail />} />
              <Route path="service/service-call/part-return/:codServ" element={<DevolucionView />} />
              <Route path="service/service-equipment-components/:codServ" element={<ServiceEquipComponents />} />
              <Route path="service/return-part/:codNotEntr" element={<PartDevolucion />} />
              {/* RUTA PRINCIPAL PARA LOS REPORTES DE SERVICIO */}
              <Route path="service/reports" element={<ServiceReportsPrincipal />} />


              {/* ----> LOGISTIC ROUTE <---- */}
              <Route path="logistic-principal" element={<LogisticView />} />
              <Route path="price-request/:id" element={<SolPrecioView />} />
              <Route path="part-detail/:codDetSolR/:solRepuesto" element={<PartView />} />
              <Route path="order-detail/:id" element={<OrderDetailView />} />
              <Route path="request-part-detail/:solRepuestoId" element={<SolRepuestoDetailView />} />
              <Route path="order-tracking/:orderNum/:idEtapa" element={<OrderTracker />} /> {/* ESTA RUTA ES PARA CAMBIAR LAS ETAPAS DE LAS ORDENES LUEGO DE QUE SON ENVIADAS AL SISTEMA ADMINISTRATIVO */}
              <Route path="supplier-data" element={<DataSupplierView />} />
              <Route path="supplier-data/create-supplier" element={<CreateSupplierView />} />
              <Route path="ship-to" element={<ShipToLogisticView />} />
              <Route path="ship-to/detail/:codShipTo" element={<ShipToLogisticViewDetail />} />
              <Route path="logistic-follow-up" element={<SeguimientoLogistica />} />
              <Route path="logistic/zone-support-approval/approval-detail/:codSolRep" element={<AproSoporteDetail />} />
              <Route path="logistic-reports-view" element={<LogisticReportsView />} />
              <Route path="logistic/order-detail-reader/:orderNum" element={<OrderDetailViewRead />} />
              {/* REPORTES DE LOGISTICA */}
              <Route path="logistic-reports-projections/search" element={<LogisticProjectionOrdersView />} />
              <Route path="logistic-reports-parts-export/search" element={<LogisticPiezasParaExportView />} />

              {/* ----> HUMAN RESOURCE ROUTES <---- */}
              <Route path="human-capital/employees" element={<HumanReView />} />
              <Route path="human-capital/employees/new-employee" element={<NewEmployee />} />
              <Route path="human-capital/employee/modify/:id" element={<ModifyEmployee />} />
              <Route path="human-capital/pendings" element={<PendingsRH />} />
              <Route path="human-capital/vacations/request" element={<SolicitudesVacaPerm />} />
              <Route path="human-capital/vacations/request/detail/:id" element={<VacacionesPermisosDetail />} />

              {/* ----> Mis Pendientes <---- */}
              {/* <Route path="my-tasks" element={<MyTasksPending />} /> */}
              <Route path="my-tasks/commercial" element={<CommercialProfile />} />
              <Route path="my-tasks/service" element={<ServiceProfile />} />
              <Route path="my-tasks/site-planning" element={<SitePlanningProfile />} />
              <Route path="my-tasks/operation" element={<OperationProfile />} />


              {/* ----> VIATICOS <---- */}
              <Route path="travel-expenses" element={<AnticipoPrincipal />} />
              <Route path="travel-expenses/approvals" element={<AprobacionPrincipal />} />
              <Route path="travel-expenses/expense-detail/:codSolG" element={<AnticipoDetailView />} />
              <Route path="travel-expenses/approve-detail/:codSolG" element={<AprobacionDetail />} />
              <Route path="travel-expenses/approve-detail/:codSolG/:codDet" element={<AprobacionDetalleDetail />} />
              <Route path="travel-expenses/expense-detail/:codSolG/:codDet" element={<AnticipoDetalleDetail />} />
              <Route path="travel-expenses/create-detail-advance/:codSolG" element={<CreateDetalleAnticipo />} />
              <Route path="travel-expenses/expense-report-detail/:codSolG" element={<RelacionGastoDetail />} />
              <Route path="travel-expenses/expense-report-detail/:codSolG/:codDet" element={<RelacionGastoDetailDetalle />} />
              <Route path="travel-expenses/create-advance" element={<CreateAnticipo />} />
              <Route path="travel-expenses/create-expense-report" element={<RelacionGastoNueva />} />
              <Route path="travel-expenses/create-detail-expense-report/:codSolG" element={<CreateDetalleRelacion />} />
              <Route path="travel-expenses/expense-report" element={<RelacionGastoPrincipal />} />

              {/* ----> COMMERCIAL <---- */}
              <Route path="commercial/purchase-order" element={<CommercialPurchaseOrder />} />
              <Route path="commercial/purchase-order/order-detail/:codOrden" element={<CommePurchaseOrderDetail />} />
              <Route path="commercial/purchase-order/order-detail/:codOrdenDet" element={<CommercialBackLogDetail />} />
              <Route path="commercial/projects" element={<ProjectsView />} />
              <Route path="commercial/projects/projects-detail/:idService" element={<ProjectsDetailView />} />
              <Route path="commercial/request-orders" element={<CommercialRequestOrdersView />} />
              <Route path="commercial/request-new-order" element={<CreateBackLogView />} />
              <Route path="commercial/request-order-detail/:codOrdenDet" element={<BackLogDetail />} />
              <Route path="commercial/principal" element={<PendingCommercialView />} />
              <Route path="commercial/service" element={<ServiceView grupo={3} />} />
              <Route path="commercial/service/new-call" element={<NewCall grupo={3} />} />
              <Route path="commercial/service/service-report/:idCall" element={<ServiceReport grupo={3} />} />
              <Route path="commercial/service/service-call/:id" element={<ServiceCallsDetails grupo={3} />} />
              <Route path="commercial/service-report-detail/:codRepServ" element={<ServiceReportsDetailView grupo={3} />} />
              <Route path="commercial/create-order" element={<CreateOrderCommercial />} /> {/* Esta ruta es para las ordenes de comercial (modulo nuevo) para darle el siguimiento a los proyectos */}
              <Route path="commercial/order-detail/:codSol" element={<OrderDetailCommercialView />} />
              <Route path="commercial/reports-view" element={<CommercialReportsView />} />
              <Route path="commercial/service/app-list" element={<ApplicationListView />} />
              <Route path="commercial/reports-view/open-calls-report" element={<LlamadasAbiertas />} />


              {/* POST VENTA */}
              <Route path="post-sales/principal" />
              <Route path="post-sales/proposal" element={<PropuestasPostVenta />} />
              <Route path="/post-sales/proposal/proposal-detail/:codPropuesta" element={<PropuestaDetail />} />
              <Route path="/post-sales/quotations" element={<QuotationViewPrincipal />} />
              <Route path="/post-sales/quotation-detail/:codSolRepuesto/:cotNum" element={<QuotationDetail />} />

              {/* ----> ALMACEN RUTAS <---- */}
              <Route path="warehouse/principal" element={<WareHouseViewPrincipal />} />
              <Route path="warehouse/dispatch-instructions/:codSolRepuesto" element={<OrdTransitWaitingDispatch />} />
              <Route path="warehouse/technical-approval" element={<TechnicalApprovalView />} />
              <Route path="warehouse/technical-approval/technical-detail/:codSolRepuesto" element={<TechnicalApprovalsDetail />} />
              <Route path="warehouse/administrative-approval" element={<AdministrativeApprovals />} />
              <Route path="warehouse/administrative-approval/approval-detail/:codSolRep" element={<AdministrativeApprovalsDetail />} />
              <Route path="warehouse/delivery-notes/:codSolRepuesto" element={<NotaEntregaDetailView />} />
              <Route path="warehouse/delivery-notes-print/:notEntr" element={<NotaEntregaDetailPrint />} />
              <Route path="warehouse/withdrawal-part-detail/:codNotRet" element={<ConfirRetPartesDetail />} />
              <Route path="warehouse/tools" element={<ToolsView />} />
              <Route path="warehouse/tools-detail/:codHerr" element={<ToolsDetailView />} />
              <Route path="warehouse/tools-detail/history/:codHerr" element={<ToolsHistory />} />
              <Route path="warehouse/parts-arrival-detail/:codSolRep" element={<LlegadaPartesDetail />} />
              <Route path="warehouse/withdrawal-detail/:codSolRep" element={<NotaRetiroDetail />} />
              <Route path="warehouse/tool-exit/:reqId" element={<SalidaHerramientaView />} />

              {/* ----> ADMINISTRATION <---- */}
              <Route path="administration/pending" element={<AdministrationPending />} />
              <Route path="administration/expenses/history" element={<HistoryExpensesView />} />
              <Route path="administration/payment-expenses" element={<ListPagosViat />} />
              <Route path="administration/authorizations" element={<AuthorizationsView />} />
              <Route path="administration/create-consumption-request" element={<CreateSolConsumoView />} />
              <Route path="administration/consumption-request" element={<SolConsumpViewPrin />} />
              <Route path="administration/consumption-request-detail/:codSol" element={<SolConsumoDetailView />} />
              <Route path="administration/report-consumption-request" element={<ReportsConsumRequest />} />

              {/* ----> DATA <---- */}
              <Route path="data/division" element={<DivisionDataView />} />
              <Route path="data/line" element={<LineaDataView />} />
              <Route path="data/modality" element={<ModalidadDataView />} />
              <Route path="data/equipment-type" element={<TipEquipDataView />} />
              <Route path="data/model" element={<ModeloDataView />} />
              <Route path="data/target" element={<TargetDataView />} />
              <Route path="data/area" element={<ZonaDataView />} />
              <Route path="data/region" element={<RegionIslandDataView />} />
              <Route path="data/city" element={<CiudadDataView />} />

              {/* Rutas para los reportes */}
              <Route path="reports" element={<ReportsView />} />

              {/* Ruta para las reservaciones de los salones de conferencia */}
              <Route path="reservations" element={<ReservationsView />} />

            </Route>
          </Routes>
        </LangProvider>
      </UserProvider>
    </>
  );
};