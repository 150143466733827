
// useFetchData para solicitar la data de la actualización de las ordenes por seguimiento

import { useEffect, useState } from "react";

// global function imports
import { getTheToken } from "../../../global-functions/globalFunctions";

// react router dom import
import { useNavigate } from "react-router-dom";


export const useFetchData = (END_POINT = "", Method = "", update = false, OrderNum = "", IdEtapa = "", NexStep = 0) => {

  // state definition
  const [partes, setPartes] = useState([]);
  const [controller, setController] = useState(null); // estado para cancelar la data del servidor
  const [state, setState] = useState({ isLoading: false, dataVisible: false, saving: false });
  const [form, setForm] = useState({ action: "", carrier: "", comentario: "", guiaAerea: "", fechaDesp: "", fecEstim: "", SO: "", numWR: "", dimen: "", peso: "", carrCli: "", guiDespCli: "", fecDespCli: new Date().toLocaleDateString("en-CA") });
  const [zonas] = useState({ RD: 1, CARIBE: 6, GMDH: 7 });

  const navigate = useNavigate();



  // funcion para cambiar el valor del formulario
  const handleChangeForm = (name = "", value = "") => {
    setForm((val) => ({ ...val, [name]: value }));
  };

  // funcion para cambiar el estado de enviando
  const handleChangeSend = (value = false) => {
    setState((val) => ({ ...val, saving: value }));
  };


  // funcion para limpiar los inputs cuando se actualice
  const handleClearForm = () => {
    setForm((val) => ({ ...val, action: "", carrier: "", comentario: "", guiaAerea: "", fechaDesp: "", fecEstim: "", SO: "", numWR: "", dimen: "", peso: "", carrCli: "", guiDespCli: "", fecDespCli: new Date().toLocaleDateString("en-CA") }));
  };


  // Funcion para cambiar la cantidad que llega a almacen
  const onHandleChangeCant = (codDet = 0, codSol = 0, newVal = "") => {

    if (isNaN(newVal)) {
      return;
    }

    if (Number(newVal) > 0) {
      setPartes((val) => {
        return val.map((part) => {
          if (codDet === part.CodDetSolRepuesto && codSol === part.CodSolRepuesto) {
            if (+newVal > part.Cantidad) {
              return part;
            }
            return { ...part, CantLlego: newVal };
          }
          return part;
        });
      });

    }

  };



  // fetch data to the server
  const fetchingDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    // END_POINT = `${urls.orderTrackingUpdate}/${orderNum}/${idEtapa}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "applicacion/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 400) {
        // estos parametros no son validos
      } else if (response.status === 200) {
        setPartes(data?.partsOrder);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/"); // el usuario no esta autenticado o venció el token, tiene que iniciar sesion nuevamente
      } else if (response.status === 500) {
        // ocurrió un error en el navegador
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos
  useEffect(() => {

    fetchingDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [OrderNum, IdEtapa]);


  // efecto de redireccion
  useEffect(() => {
    if (update) {
      const timer = setTimeout(() => {
        if (NexStep === Number(IdEtapa)) {
          // recargar la pagina
          fetchingDataServer();
        } else {

          if ([30, 31, 33, 34, 35, 36, 37, 38, 48].includes(NexStep)) {

            navigate(`/order-tracking/${OrderNum}/${NexStep}`);

          } else {

            navigate("/logistic-principal");

          }
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      }

    }
  }, [update]);



  return {
    zonas,
    state,
    form,
    partes,
    onHandleChangeCant,
    handleChangeForm,
    handleClearForm,
    handleChangeSend
  };
};